angular.module('expedientes', [])
       .controller('expedientesController', ['onlineStatus', 'authenticationInfo', '$rootScope', '$scope', '$location', '$route', '$routeParams', '$compile', 'expedientesService', 'Excel', 'uiGmapGoogleMapApi', '$filter', function (onlineStatus, authenticationInfo, $rootScope, $scope, $location, $route, $routeParams, $compile, expedientesService, Excel, uiGmapGoogleMapApi, $filter) {
           //Comprobar la conexion a internet para mostrarlo
           $scope.onlineStatus = onlineStatus;
           $scope.estado = null;
           $scope.authentication = authenticationInfo;
           $scope.$watch('onlineStatus.isOnline()', function (online) {
               $scope.online_status_string = online ? 'online' : 'offline';
               $scope.estado = $scope.online_status_string;
           });
           $scope.GISiniciado = 0;
           $scope.TabIniGIS = ($routeParams.gisC == 1 || $routeParams.gisE == 1) ? 1 : 0;

           expedientesService.getClave($routeParams.id)
                             .success(function (data) {
                                 $scope.clave = data;
                                 $rootScope.clave = data;
                                 //Inicio Iker
                                 $scope.kmlLayerOptions = {
                                     url: $scope.clave.KMLFincas,
                                     preserveViewport: ($rootScope.zoomExpedientes==null) ? false : true
                                 };
                                 //$scope.terminosmunicipales = $scope.clave.TerminosMunicipales;
                                 //$scope.asignacionexpedientes = [];
                                 //Fin Iker
                             });

           expedientesService.getClavesInformes($routeParams.id)
                  .success(function (data) {
                      $scope.claveInforme = data;
                  });

           
            //expedientesService.getFirmas($routeParams.id)
            //        .success(function (data) {
            //            $scope.firmas = data;
            //        });
            //expedientesService.getFirmas($routeParams.id)
            //        .success(function (data) {
            //            $scope.anteFirmas = data;
            //        });

            //$scope.$watch('terminomunicipalfiltro', function () {
            //    $scope.asignacionexpedientefiltro = "";
            //    $scope.pagoexpediente = null;
            //    var terminoMunicipalSeleccionado = $filter('filter')($scope.clave.TerminosMunicipales, { Id: parseInt($scope.terminomunicipalfiltro) }, true)[0];
            //    if (terminoMunicipalSeleccionado != null) {
            //        $scope.asignacionexpedientes = terminoMunicipalSeleccionado.AsignacionesExpedientes;
            //    } else {
            //        $scope.asignacionexpedientes = [];
            //    }
            //});

           //GIS
           $scope.NoGis = function () {
               $routeParams.gisC = 0;
           }
           $scope.SiGis = function () {
               $routeParams.gisC = 1;
           }

           $scope.verDetalleExpediente = function (idExpediente) {
               $rootScope.centerExpedientes = $scope.map.control.getGMap().getCenter();
               $rootScope.zoomExpedientes = $scope.map.control.getGMap().getZoom();
               $location.url('/expedientes/' + $routeParams.id + '/' + idExpediente + "?gisC=" + $routeParams.gisC + "&gisE=1");
           }

           $scope.descargarDocumento = function (idDocumento, nombre, pertenencia) {
               expedientesService.getDocumentoAlmacenado(idDocumento, pertenencia)
                    .success(function (data, headers) {
                        var filename = nombre;
                        //1 Planos Finca
                        //2 Planos Clave
                        var contentType = 'undefined';//(pertenencia == 1 || pertenencia==2) ? 'undefined' : 'application/msword';

                        var blob = new Blob([data],
                        { type: contentType });
                        if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                            window.navigator.msSaveBlob(blob, filename);
                        else //Resto
                        {
                            var downloadLink = document.createElement("a");
                            var url = window.URL.createObjectURL(blob);
                            downloadLink.setAttribute("target", "_blank");
                            downloadLink.setAttribute("href", url);
                            downloadLink.setAttribute("download", filename);
                            document.body.appendChild(downloadLink);
                            downloadLink.click();
                            document.body.removeChild(downloadLink);
                        }
                    })
                    .error(function (response) {
                        $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                        alert('Error al obtener el documento.');
                    });
           }

           $scope.initGIS = function () {
               try {
                   uiGmapGoogleMapApi.then(function (maps) {
                       $scope.GISiniciado = 1;
                       var zoomInicial = 14;
                       if ($rootScope.zoomExpedientes != null) {
                           zoomInicial = $rootScope.zoomExpedientes;
                       }
                       $scope.map = {
                           center: {        // set on Demarcaci�n de Carreteras de Arag�n as initial default
                               latitude: ($rootScope.centerExpedientes == null) ? 41.654373 : $rootScope.centerExpedientes.lat(),
                               longitude: ($rootScope.centerExpedientes == null) ? -0.878851 : $rootScope.centerExpedientes.lng()
                           },
                           zoom: zoomInicial,
                           show: true,
                           options: {
                               mapTypeId: google.maps.MapTypeId.SATELLITE
                           },
                           control: {},
                           KmlLayerCallback: function (layer) {
                               google.maps.event.addListener(layer, 'click', function (event) {
                                   var datosPlano = "<div>No Existe Documentaci\u00F3n Gr\u00E1fica<div>";
                                   //var datosPlano = "<div>No Existe Documentaci�n Gr�fica</div>";
                                   if (event.featureData != null) {
                                       if (event.featureData.name != null) {
                                           if (!(isNaN(event.featureData.name))) {
                                               var innerHtmlInfoWindow = "";
                                               angular.forEach($scope.expedientes, function (expediente) {
                                                   if (expediente.Id == event.featureData.name) {
                                                       if (expediente.Archivos!=null) {
                                                       //if (expediente.Archivos.length > 0) {
                                                           //datosPlano = "<div><button class=\"btn btn-default\" ng-click=\"descargarDocumento(" + expediente.Archivos[0].Id + ", '" + expediente.Archivos[0].Nombre + "', 1)\" >Documentaci\u00F3n Gr\u00E1fica del Proyecto</button><br></div>"
                                                           datosPlano = "<div><a href=\"\" ng-click=\"descargarDocumento(" + expediente.Archivos.Id + ", '" + expediente.Archivos.Nombre + "',1);\" style=\"cursor: pointer;\">Documentaci\u00F3n Gr\u00E1fica de la Finca</a><br></div>";
                                                       }
                                                       //innerHtmlInfoWindow = "<div style=\"font-family: Roboto,Arial,sans-serif; font-size: small;\"><div style=\"font-size: medium; font-weight: 500; margin-bottom: 0em;\">" + expediente.TerminoMunicipal + "</div><div>Num. Finca: " + expediente.NumFinca + "</div><div>Pol\u00edgono: " + expediente.Poligono + "</div><div>Parcela: " + expediente.Parcela + "</div><div><a href=\"" + $location.protocol() + "://" + $location.host() + ":" + $location.port() + '/#/expedientes/' + $routeParams.id + "/" + event.featureData.name + "?gisC=" + $routeParams.gisC + "&gisE=1\">Ver expediente</a><br></div>" + datosPlano + "</div>";
                                                       innerHtmlInfoWindow = "<div style=\"font-family: Roboto,Arial,sans-serif; font-size: small;\"><div style=\"font-size: medium; font-weight: 500; margin-bottom: 0em;\">" + expediente.TerminoMunicipal + "</div><div>Num. Finca: " + expediente.NumFinca + "</div><div>Pol\u00edgono: " + expediente.Poligono + "</div><div>Parcela: " + expediente.Parcela + "</div><div><a ng-click=\"verDetalleExpediente(" + event.featureData.name + ");\" style=\"cursor: pointer;\">Ver expediente</a><br></div>" + datosPlano + "</div>";
                                                   }
                                               });
                                               var htmCompilado = $compile(angular.element(innerHtmlInfoWindow))($scope);
                                               event.featureData.infoWindowHtml = htmCompilado[0];
                                           }
                                       }
                                   }
                               });
                           }
                       }
                       //google.maps.event.trigger($scope.map, 'resize');
                   })
               } catch (e) {
                   //alert(e);
               }
           }
           if ($scope.TabIniGIS == 1) {
               $scope.initGIS();
           }

           $("a[data-target='#gis']").on('shown.bs.tab', function () {
               if ($scope.clave.KMLFincas == null || $scope.clave.KMLFincas == '')
                   alert('No existe GIS para este expediente');

               if ($scope.GISiniciado == 0) {
                   $scope.initGIS();
               }
           });
           //Fin GIS

           expedientesService.getExpedientes($routeParams.id)
                             .success(function (data) {
                                 $scope.expedientes = data;
                             });

           $scope.volver = function () {
               $location.url('/claves' + "?gisC=" + $routeParams.gisC);
           }

           $scope.verExpediente = function (idExpediente) {
               $location.url('/expedientes/' + $routeParams.id + "/" + idExpediente + "?gisC=" + $routeParams.gisC + "&gisE=0");
           }
           $scope.nuevoExpediente = function () {
               $location.url('/expedientes/' + $routeParams.id + "/0" + "?gisC=" + $routeParams.gisC + "&gisE=0");
           }
           $scope.eliminarExpediente = function (idExpediente) {
               expedientesService.deleteExpediente(idExpediente)
                   .success(function (data) {
                       var exp = $filter('filter')($scope.expedientes, { Id: idExpediente })[0];
                       var index = $scope.expedientes.indexOf(exp);
                       $scope.expedientes.splice(index, 1);
                       alert("Expediente eliminado correctamente");
                   })
                    .error(function (response) {
                        $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                        alert($scope.error);
                    });
           }

           $scope.exportarExcel = function () {
               //Excel.exportar('dvExpedientes', 'tbExpedientes', 'Expedientes');
           };

           //Listados
           expedientesService.getCarreteras()
           .success(function (data) {
               $scope.carreteras = data;
           });
           expedientesService.getProvincias()
           .success(function (data) {
               $scope.provincias = data;
           });
           expedientesService.getTiposVia()
           .success(function (data) {
               $scope.tiposvia = data;
           });
           expedientesService.getTiposObra()
          .success(function (data) {
              $scope.tiposobra = data;
          });
           expedientesService.getEstados()
           .success(function (data) {
               $scope.estados = data;
           });

           expedientesService.getEstadosClave($routeParams.id)
           .success(function (data) {
               $scope.estadosclave = data;
           });

           $scope.seleccionarListado = function (opcion) {               
               $scope.ListadoSeleccionado = opcion;
           }
          
                   
                   $scope.seleccionClavesListados = [];
                   $scope.FechaFiltroListados = null;
                   $scope.toggleSeleccionClavesListados = function toggleSeleccionClavesListados(idClave) {
                       var idx = $scope.seleccionClavesListados.indexOf(idClave);
                       if (idx > -1) {
                           $scope.seleccionClavesListados.splice(idx, 1);
                       } else {
                           $scope.seleccionClavesListados.push(idClave);
                       }
                   };
                   $scope.seleccionCarreterasListados = [];
                   $scope.toggleSeleccionCarreterasListados = function toggleSeleccionCarreterasListados(idCarretera) {
                       var idx = $scope.seleccionCarreterasListados.indexOf(idCarretera);
                       if (idx > -1) {
                           $scope.seleccionCarreterasListados.splice(idx, 1);
                       } else {
                           $scope.seleccionCarreterasListados.push(idCarretera);
                       }
                   };
                   $scope.seleccionMunicipiosListados = [];
                   $scope.toggleSeleccionMunicipiosListados = function toggleSeleccionMunicipiosListados(idMunicipio) {
                       var idx = $scope.seleccionMunicipiosListados.indexOf(idMunicipio);
                       if (idx > -1) {
                           $scope.seleccionMunicipiosListados.splice(idx, 1);
                       } else {
                           $scope.seleccionMunicipiosListados.push(idMunicipio);
                       }
                   };
                   $scope.seleccionProvinciasListados = [];
                   $scope.toggleSeleccionProvinciasListados = function toggleSeleccionProvinciasListados(idProvincia) {
                       var idx = $scope.seleccionProvinciasListados.indexOf(idProvincia);
                       if (idx > -1) {
                           $scope.seleccionProvinciasListados.splice(idx, 1);
                       } else {
                           $scope.seleccionProvinciasListados.push(idProvincia);
                       }
                   };
                   $scope.seleccionTiposViaListados = [];
                   $scope.toggleSeleccionTiposViaListados = function toggleSeleccionTiposViaListados(idTipoVia) {
                       var idx = $scope.seleccionTiposViaListados.indexOf(idTipoVia);
                       if (idx > -1) {
                           $scope.seleccionTiposViaListados.splice(idx, 1);
                       } else {
                           $scope.seleccionTiposViaListados.push(idTipoVia);
                       }
                   };
                   $scope.seleccionTiposObraListados = [];
                   $scope.toggleSeleccionTiposObraListados = function toggleSeleccionTiposObraListados(idTipoObra) {
                       var idx = $scope.seleccionTiposObraListados.indexOf(idTipoObra);
                       if (idx > -1) {
                           $scope.seleccionTiposObraListados.splice(idx, 1);
                       } else {
                           $scope.seleccionTiposObraListados.push(idTipoObra);
                       }
                   };

                   $scope.seleccionEstados = [];
                   $scope.toggleSeleccionEstados = function toggleSeleccionEstados(idEstado) {
                       var idx = $scope.seleccionEstados.indexOf(idEstado);
                       if (idx > -1) {
                           $scope.seleccionEstados.splice(idx, 1);
                       } else {
                           $scope.seleccionEstados.push(idEstado);
                       }
                   };

                   $scope.limpiarFiltrosListados = function limpiarFiltrosListados() {
                       $scope.seleccionClavesListados = [];
                       $scope.seleccionCarreterasListados = [];
                       $scope.seleccionMunicipiosListados = [];
                       $scope.seleccionProvinciasListados = [];
                       $scope.seleccionTiposViaListados = [];
                       $scope.seleccionTiposObraListados = [];
                       $scope.seleccionEstados = [];
                   };

            


           $scope.listados = function () {
               
               //Pasar los IDs en arrays a string
               var strIDs = "";
               for (var i = 0; i < $scope.seleccionClavesListados.length; i++) {
                   strIDs += $scope.seleccionClavesListados[i] + ",";
               }
               strIDs += "||";
               for (var i = 0; i < $scope.seleccionCarreterasListados.length; i++) {
                   strIDs += $scope.seleccionCarreterasListados[i] + ",";
               }
               strIDs += "||";
               for (var i = 0; i < $scope.seleccionProvinciasListados.length; i++) {
                   strIDs += $scope.seleccionProvinciasListados[i] + ",";
               }
               strIDs += "||";
               for (var i = 0; i < $scope.seleccionMunicipiosListados.length; i++) {
                   strIDs += $scope.seleccionMunicipiosListados[i] + ",";
               }
               strIDs += "||";
               for (var i = 0; i < $scope.seleccionTiposViaListados.length; i++) {
                   strIDs += $scope.seleccionTiposViaListados[i] + ",";
               }
               strIDs += "||";
               for (var i = 0; i < $scope.seleccionTiposObraListados.length; i++) {
                   strIDs += $scope.seleccionTiposObraListados[i] + ",";
               }
               strIDs += "||";
               for (var i = 0; i < $scope.seleccionEstados.length; i++) {
                   strIDs += $scope.seleccionEstados[i] + ",";
               }
               //var fecha = ($scope.FechaFiltroListados==null) ? null: $filter('date')($scope.FechaFiltroListados, "yyyy-MM-dd");

                expedientesService.getListado($scope.ListadoSeleccionado, $routeParams.id, strIDs, $scope.FechaFiltroListados)
                    .success(function (data, status, headers) {
                        var filename = headers("x-filename");
                        switch ($scope.ListadoSeleccionado) {
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 14:
                                var contentType = 'application/vnd.ms-excel';
                                break;
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13: 
                                var contentType = 'application/ms-word';
                                break;
                        }

                        $scope.ListadoSeleccionado = null;                         

                        var blob = new Blob([data], { type: contentType });
                        if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                            window.navigator.msSaveBlob(blob, filename);
                        else //Resto
                        {
                            var downloadLink = document.createElement("a");
                            var url = window.URL.createObjectURL(blob);
                            downloadLink.setAttribute("target", "_blank");
                            downloadLink.setAttribute("href", url);
                            downloadLink.setAttribute("download", filename);
                            document.body.appendChild(downloadLink);
                            downloadLink.click();
                            document.body.removeChild(downloadLink);
                        }

                        $scope.limpiarFiltrosListados();
                    })
                    .error(function (response) {
                        $scope.ListadoSeleccionado = null;
                        $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                        alert('Error al obtener el listado.');
                        $scope.limpiarFiltrosListados();
                    });
            }
           //Fin Listados

           //Inicio Iker
           expedientesService.getTerminosMunicipalesClave($routeParams.id)
             .success(function (data) {
                 $scope.terminosmunicipales = data;
             });

           expedientesService.getAsignaciones($routeParams.id)
              .success(function (data) {
                  $scope.asignacionExpedientes = data;
              });

           expedientesService.getEstados()
              .success(function (data) {
                  $scope.estados = data;
              });

           $scope.propietariosPorMunicipioCargar = function (idTerminoMunicipal) {
               $scope.idTerminoMunicipalTransferencia = idTerminoMunicipal;
               expedientesService.getPropietarioPorTerminoMunicipal(idTerminoMunicipal)
                .success(function (data) {
                    $scope.propietarios = data;
                });
           };

           $scope.propietariosPorMunicipioOficios = function (idTerminoMunicipal) {
               expedientesService.getPropietariosPorMunicipioOficio(idTerminoMunicipal, $scope.claveInforme.Id)
               .success(function (data) {
                   $scope.propietarios = data;
               });
           };

           $scope.expedientesPorPropietarioCargar = function (idPropietario) {
               expedientesService.getExpedientesPorPropietario(idPropietario, $scope.idTerminoMunicipalTransferencia, $routeParams.id)
                .success(function (data) {
                    $scope.expedientesPorPropietario = data;
                });
           };

           $scope.idMunicipioActualizar = function (idTerminoMunicipal) {
               $scope.idTerminoMunicipalTransferencia = idTerminoMunicipal;
           };
           $scope.expedientesPorAsignacionCargar = function (descripcionAsignacion) {
               if ($scope.idTerminoMunicipalTransferencia == undefined) {
                   alert('Debe seleccionar un T\u00e9rmino Municipal');
                   return;
               }
               expedientesService.getExpedientesPorAsignacion(descripcionAsignacion, $scope.idTerminoMunicipalTransferencia, $routeParams.id)
                .success(function (data) {
                    $scope.expedientesPorAsignacion = data;
                })
                 .error(function (response) {
                     alert('Error');
                 });
           };

           $scope.seleccionarDocumentacion = function (doc) {
               //$scope.fechaEspecificaInforme = false;
               $scope.mostrarEstadoComunicacionPago = false;
               $scope.DocumentoSeleccionado = doc;
               $scope.TituloFechaDocSeleccionado = '';

               $scope.seleccionarTodosLosExpedientes = false;
               angular.forEach($scope.expedientes, function (expediente) {
                   expediente.Selected = $scope.seleccionarTodosLosExpedientes;
               });

               $('#myModalSeleccionDocumentacion').modal('hide');
               $('#myModalSeleccionListados').modal('hide');
               switch (doc) {
                   case 'CitacionAP':
                       $scope.TituloDocumento = 'Citaci\u00F3n Acta Previa';
                       $scope.NombreDocumento = "CitacionActaPrevia";
                       $scope.TituloFechaDocSeleccionado = 'Fecha Levantamiento Actas Previas';
                       //$scope.fechaEspecificaInforme = true;
                       break;
                   case 'CitacionCP':
                       $scope.TituloDocumento = 'Citaci\u00F3n Pago DP e IRO';
                       $scope.NombreDocumento = "CitacionPago";
                       $scope.TituloFechaDocSeleccionado = 'Fecha Citaci\u00F3n Pago';
                       //$scope.fechaEspecificaInforme = true;
                       break;
                   case 'ActasMutuoAcuerdo':
                       $scope.TituloDocumento = 'Remisi\u00F3n Actas Mutuo Acuerdo';
                       $scope.NombreDocumento = "RemisionActasMutuoAcuerdo";
                       break;
                   case 'ComunicacionPago':
                       $scope.TituloDocumento = 'Comunicaci\u00F3n Pago';
                       $scope.NombreDocumento = "ComunicacionPago";
                       $scope.TituloFechaDocSeleccionado = 'Fecha Comunicaci\u00F3n Pago';
                       $scope.mostrarEstadoComunicacionPago = true;
                       break;
                   case 'NotificacionTransferencia':
                       $scope.TituloDocumento = 'Notificaci\u00F3n Transferencia';
                       $scope.NombreDocumento = "NotificacionTransferencia";
                       break;
                   case 'RequerimientoValoracion':
                       $scope.TituloDocumento = 'Requerimiento Valoraci\u00F3n';
                       $scope.NombreDocumento = "RequerimientoValoracion";
                       break;
                   case 'RemisionHAASinValoracion':
                       $scope.TituloDocumento = 'Remisi\u00F3n HAA';
                       $scope.NombreDocumento = "RemisionHAASinValoracion";
                       break;
                   case 'RemisionHAAConValoracion':
                       $scope.TituloDocumento = 'Rechazo HAP y Remisi\u00F3n HAA';
                       $scope.NombreDocumento = "RemisionHAAConValoracion";
                       break;
                   case 'OcupacionTemporal':
                       $scope.TituloDocumento = 'Valoraci\u00F3n Ocupaci\u00F3n Temporal';
                       $scope.NombreDocumento = "OcupacionTemporal";
                       break;
                   case 'NotificacionOcupacion':
                       $scope.TituloDocumento = 'Notificaci\u00F3n Ocupaci\u00F3n de la finca';
                       $scope.NombreDocumento = "NotificacionOcupacion";
                       $scope.TituloFechaDocSeleccionado = 'Fecha Notificaci\u00F3n Ocupaci\u00F3n';
                       //$scope.fechaEspecificaInforme = true;
                       break;
                   case 'RequerimientoValoracion2':
                       $scope.TituloDocumento = 'Requerimiento Valoraci\u00F3n';
                       $scope.NombreDocumento = "RequerimientoValoracion2";
                       break;

                   case 'ActapreviaOcupacion':
                       $scope.TituloDocumento = 'Acta Previa Ocupaci\u00F3n';
                       $scope.NombreDocumento = "ActaPreviaOcupacion";
                       break;
                   case 'HojaValoracionDP':
                       $scope.TituloDocumento = 'Hoja Valoraci\u00F3n D.P.';
                       $scope.NombreDocumento = "HojaValoracionDPOcupacion";
                       break;
                   case 'HojaValoracionIRO':
                       $scope.TituloDocumento = 'Hoja Valoraci\u00F3n IRO';
                       $scope.NombreDocumento = "HojaValoracionIRO";
                       break;
                   case 'ActaOcupacion':
                       $scope.TituloDocumento = 'Acta Ocupaci\u00F3n';
                       $scope.NombreDocumento = "ActaOcupacion";
                       break;
                   case 'ActaMA':
                       $scope.TituloDocumento = 'Acta Mutuo Acuerdo';
                       $scope.NombreDocumento = "ActaMutuoAcuerdo";
                       break;
                   case 'HojaAprecioAdministracion':
                       $scope.TituloDocumento = 'Hoja Aprecio Administraci\u00F3n';
                       $scope.NombreDocumento = "HojaAprecioAdministracion";
                       break;
                   case 'ConvenioOcupacionTemporal':
                       $scope.TituloDocumento = 'Convenio Ocupaci\u00F3n Temporal';
                       $scope.NombreDocumento = "ConvenioOcupacionTemporal";
                       break;
                   case 'HojaAprecioAdministracion2':
                       $scope.TituloDocumento = 'Hoja Aprecio Administraci\u00F3n';
                       $scope.NombreDocumento = "HojaAprecioAdministracion2";
                       break;

                   case 'SolicitudTransferencia':
                       $scope.TituloDocumento = 'Solicitud Transferencia';
                       $scope.NombreDocumento = "SolicitudTransferencia";
                       $scope.TituloFechaDocSeleccionado = 'Fecha de Pago';
                       break;
                   case 'ExpedienteLiquidacion':
                       $scope.TituloDocumento = 'Expediente Liquidaci\u00F3n';
                       $scope.NombreDocumento = "ExpedienteLiquidacion";
                       break;
                   case 'RelacionPago':
                       $scope.TituloDocumento = 'Relaci\u00F3n de Pago';
                       $scope.NombreDocumento = "RelacionPago";
                       break;

                   case 'ReportajeFotografico':
                       $scope.TituloDocumento = 'Reportaje Fotografico';
                       $scope.NombreDocumento = "ReportaFotografico";
                       break;

                   case 'CarpetaA3':
                       $scope.TituloDocumento = 'Carpeta expediente formato A3';
                       $scope.NombreDocumento = "CarpetaA3";
                       break;

                   case 'ActaOcupacionDPZ':
                       $scope.TituloDocumento = 'Acta Ocupaci\u00F3n DPZ';
                       $scope.NombreDocumento = "ActaOcupacionDPZ";
                       break;

                   case 'ActaBienesDP':
                       $scope.TituloDocumento = 'Acta Bienes DP';
                       $scope.NombreDocumento = "ActaBienesDP";
                       break;


                   case 'ListadoPropietariosCitacion':
                       $scope.TituloDocumento = 'Listado de Propietarios con Citación para AP';
                       $scope.NombreDocumento = "ListadoPropietariosCitacion";
                       break;
                   case 'ListadoBienesYDerechos':
                       $scope.TituloDocumento = 'Listado de Bienes y Derechos Afectados';
                       $scope.NombreDocumento = "ListadoBienesYDerechosAfectados";
                       break;
                   case 'ListadoPropietariosFinca':
                       $scope.TituloDocumento = 'Listado de Propietarios Por Finca';
                       $scope.NombreDocumento = "ListadoPropietariosFinca";
                       break;
                   case 'ListadoPropietariosApellido':
                       $scope.TituloDocumento = 'Listado de Propietarios Por Apellido';
                       $scope.NombreDocumento = "ListadoPropietariosApellido";
                       break;
                   case 'ListadoFirmasRecibis':                       
                       $scope.TituloDocumento = 'Listado de Firmas y Recibis';
                       $scope.NombreDocumento = "ListadoFirmasRecibis";
                       break;
                   case 'ListadoPropietariosEstado':
                       $scope.TituloDocumento = 'Listado de Propietarios por Estado';
                       $scope.NombreDocumento = "ListadoPropietariosEstado";
                       break;

               }
           }

           $scope.seleccionarTodosLosExpedientes = false;
           var filter = $filter('filter');
           var expedientesFiltrados;
           $scope.marcarExpedientesComoSeleccionados = function () {
               if ($scope.seleccionarTodosLosExpedientes) {
                   $scope.seleccionarTodosLosExpedientes = false;
                   angular.forEach($scope.expedientes, function (expediente) {
                       expediente.Selected = $scope.seleccionarTodosLosExpedientes;
                   });
               } else {
                   $scope.seleccionarTodosLosExpedientes = true;
                   expedientesFiltrados = filter($scope.expedientes, $scope.filtroExpedienteSeleccionarDocumentacion);
                   angular.forEach(expedientesFiltrados, function (expediente) {
                       expediente.Selected = $scope.seleccionarTodosLosExpedientes;
                   });
               }
           };

           $scope.generarDocumentacion = function () {
               //Inicio Iker
               var doc = $scope.DocumentoSeleccionado;
               var idsExpedientes = '';
               var idPropietario = '';
               var idTerminoMunicipal = '';
               var blnExpedientesSeleccionados = false;
               var blnOficios = false;

               //if (doc == 'CitacionAP' || doc == 'CitacionCP' || doc == 'ActasMutuoAcuerdo' || doc == 'NotificacionOcupacion') {
               //    if ($scope.FechaEspecificaInforme == '' || $scope.FechaEspecificaInforme == undefined) {
               //        alert('Debe seleccionar la ' + $scope.TituloFechaDocSeleccionado + '.');
               //        return;
               //    }
               //}
               if (doc == 'ComunicacionPago' && $scope.EstadoInformeId == undefined) {
                   alert('Debe seleccionar un estado');
                   return;
               }
               if ($scope.EstadoInformeId == undefined)
                   $scope.EstadoInformeId = 0;

               blnOficios = $scope.blnOficios;
               
               if (blnOficios == false) {
                   angular.forEach($scope.expedientes, function (expediente) {
                       if (expediente.Selected) {
                           idsExpedientes += expediente.Id + ",";
                           blnExpedientesSeleccionados = true;
                       }
                   });
                   if (idsExpedientes.length > 0)
                       idsExpedientes = idsExpedientes.substring(0, idsExpedientes.length - 1);

                   if (!blnExpedientesSeleccionados) {
                       alert('No se ha seleccionado ning�n expediente');
                       return;
                   };
                   //if ($scope.HoraEspecificaInforme != undefined) {
                   //    $scope.FechaEspecificaInforme.setHours($scope.HoraEspecificaInforme.getHours());
                   //    $scope.FechaEspecificaInforme.setMinutes($scope.HoraEspecificaInforme.getMinutes());
                   //}

                   //if ($scope.FechaEspecificaInforme == undefined)
                   //    $scope.FechaEspecificaInforme = new Date();

                   expedientesService.getDocumentos(doc, idsExpedientes, $scope.EstadoInformeId, Date.now(), Date.now(), "")
                       .success(function (data, status, headers) {
                           $scope.documentoObtenido = data;
                           var filename = headers('x-filename');
                           var contentType = 'application/msword';
                           if (idsExpedientes.indexOf(",") >= 0) {
                               filename = doc + '.zip';
                               contentType = 'application/octet-stream';
                           }

                           var blob = new Blob([data],
                               { type: contentType });

                           if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                               window.navigator.msSaveBlob(blob, filename);
                           else //Resto
                           {
                               var downloadLink = document.createElement("a");
                               downloadLink.setAttribute("target", "_blank");
                               downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                               downloadLink.setAttribute('download', filename);
                               document.body.appendChild(downloadLink);
                               downloadLink.click();
                               document.body.removeChild(downloadLink);
                           }
                           $('#myModalSeleccionDocumentacion').limpiarControlesSeleccionInforme();
                           $('#myModalSeleccionDocumentacion').modal('hide');
                       })
                       .error(function (response) {
                           $('#myModalSeleccionDocumentacion').limpiarControlesSeleccionInforme();
                           $('#myModalSeleccionExpedientes').modal('hide');
                           alert('Error al generar los documentos.');
                       });

                   $scope.FechaHoraConvocatoria = '';
                   //Fin Iker
               }

               else if ($scope.DocumentoSeleccionado == "RequerimientoValoracion2") {

                   idPropietario = $scope.propietario.Id;
                   idTerminoMunicipal = $scope.terminomunicipal.Id;

                   var doc = $scope.DocumentoSeleccionado

                   //if (idsExpedientes.length > 0)
                   //    idsExpedientes = idsExpedientes.substring(0, idsExpedientes.length - 1);

                   //if (!blnExpedientesSeleccionados) {
                   //    alert('No se ha seleccionado ning�n expediente');
                   //    return;
                   //};
                   //if ($scope.HoraEspecificaInforme != undefined) {
                   //    $scope.FechaEspecificaInforme.setHours($scope.HoraEspecificaInforme.getHours());
                   //    $scope.FechaEspecificaInforme.setMinutes($scope.HoraEspecificaInforme.getMinutes());
                   //}

                   //if ($scope.FechaEspecificaInforme == undefined)
                   //    $scope.FechaEspecificaInforme = new Date();

                   expedientesService.getDocumentosRequerimiento(doc, $scope.claveInforme.Id, idTerminoMunicipal, idPropietario, $scope.EstadoInformeId, Date.now(), Date.now(), "")
                       .success(function (data, status, headers) {
                           $scope.documentoObtenido = data;
                           var filename = headers('x-filename');
                           var contentType = 'application/msword';
                           //if (idsExpedientes.indexOf(",") >= 0) {
                               filename = doc + '.zip';
                               contentType = 'application/octet-stream';
                           //}

                           var blob = new Blob([data],
                               { type: contentType });

                           if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                               window.navigator.msSaveBlob(blob, filename);
                           else //Resto
                           {
                               var downloadLink = document.createElement("a");
                               downloadLink.setAttribute("target", "_blank");
                               downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                               downloadLink.setAttribute('download', filename);
                               document.body.appendChild(downloadLink);
                               downloadLink.click();
                               document.body.removeChild(downloadLink);
                           }
                           $('#myModalSeleccionDocumentacion').limpiarControlesSeleccionInforme();
                           $('#myModalSeleccionDocumentacion').modal('hide');
                       })
                       .error(function (response) {
                           $('#myModalSeleccionDocumentacion').limpiarControlesSeleccionInforme();
                           $('#myModalSeleccionExpedientes').modal('hide');
                           alert('Error al generar los documentos.');
                       });

                   $scope.FechaHoraConvocatoria = '';
                   //Fin Iker

               }

               else {

                   idPropietario = $scope.propietario.Id;
                   idTerminoMunicipal = $scope.terminomunicipal.Id;

                   var doc = $scope.DocumentoSeleccionado

                   expedientesService.getDocumentoOficio(doc, $scope.claveInforme.Id, idTerminoMunicipal, idPropietario, $scope.EstadoInformeId, Date.now(), Date.now(), "")
                     .success(function (data, status, headers) {
                         $scope.documentoObtenido = data;

                         doc += '_' + idTerminoMunicipal + '_' + idPropietario;

                         var filename = headers('x-filename');
                         var contentType = 'application/msword';

                         var blob = new Blob([data],
                         { type: contentType });

                         if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                             window.navigator.msSaveBlob(blob, filename);
                         else //Resto
                         {
                             var downloadLink = document.createElement("a");
                             downloadLink.setAttribute("target", "_blank");
                             downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                             downloadLink.setAttribute('download', filename);
                             document.body.appendChild(downloadLink);
                             downloadLink.click();
                             document.body.removeChild(downloadLink);
                         }
                         limpiarControlesSeleccionOficios;
                         $('#myModalSeleccionPropietariosOficios').modal('hide');
                     })
                      .error(function (response) {
                          //$('#myModalSeleccionPropietariosOficios').limpiarControlesSeleccionInforme();
                          limpiarControlesSeleccionOficios;
                          $('#myModalSeleccionPropietariosOficios').modal('hide');
                          alert('Error al generar el documento.');
                      });
               }
           }

           $scope.seleccionarTodosLosExpedientesTransferencia = false;
           $scope.marcarExpedientesTransferenciaComoSeleccionados = function () {
               if ($scope.seleccionarTodosLosExpedientesTransferencia) {
                   $scope.seleccionarTodosLosExpedientesTransferencia = false;
               } else {
                   $scope.seleccionarTodosLosExpedientesTransferencia = true;
               }
               angular.forEach($scope.expedientesPorPropietario, function (expediente) {
                   expediente.Selected = $scope.seleccionarTodosLosExpedientesTransferencia;
               });
           };

           $scope.generarDocumentacionTransferencia = function () {
               if ($scope.FechaHoraConvocatoria == '') {
                   alert('Debe seleccionar una fecha.');
                   return;
               }
               if (doc == 'ComunicacionPago' && $scope.EstadoInformeId == undefined) {
                   alert('Debe seleccionar un estado');
                   return;
               }
               if ($scope.EstadoInformeId == undefined)
                   $scope.EstadoInformeId = 0;

               if ($scope.FechaHoraConvocatoria == '' || $scope.FechaHoraConvocatoria == undefined) {
                   $scope.FechaHoraConvocatoria = Date.now();
               }
               var doc = $scope.DocumentoSeleccionado;
               var idsExpedientes = '';
               var blnExpedientesSeleccionados = false;
               angular.forEach($scope.expedientesPorPropietario, function (expediente) {
                   if (expediente.Selected) {
                       idsExpedientes += expediente.Id + ",";
                       blnExpedientesSeleccionados = true;
                   }
               });

               if (!blnExpedientesSeleccionados) {
                   alert('No se ha seleccionado ning�n expediente');
                   return;
               }

               if (idsExpedientes.length > 0)
                   idsExpedientes = idsExpedientes.substring(0, idsExpedientes.length - 1);

               expedientesService.getDocumentos(doc, idsExpedientes, $scope.EstadoInformeId, Date.now(), $scope.FechaHoraConvocatoria, "")
                    .success(function (data) {
                        $scope.documentoObtenido = data;
                        var filename = doc + '.docx';
                        var contentType = 'application/msword';
                        if (idsExpedientes.indexOf(",") >= 0) {
                            filename = doc + '.zip';
                            contentType = 'application/octet-stream';
                        }

                        var blob = new Blob([data],
                        { type: contentType });

                        if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                            window.navigator.msSaveBlob(blob, filename);
                        else //Resto
                        {
                            var downloadLink = document.createElement("a");
                            downloadLink.setAttribute("target", "_blank");
                            downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                            downloadLink.setAttribute('download', filename);
                            document.body.appendChild(downloadLink);
                            downloadLink.click();
                            document.body.removeChild(downloadLink);
                        }

                        limpiarControlesSeleccionInforme();
                        $('#myModalSeleccionDocumentacion').modal('hide');
                    })
                     .error(function (response) {
                         limpiarControlesSeleccionInforme();
                         $('#myModalSeleccionExpedientes').modal('hide');
                         alert('Error al generar los documentos.');
                     });

               $scope.FechaHoraConvocatoria = '';
           }

           $scope.seleccionarTodosLosExpedientesLiquidacion = false;
           $scope.marcarExpedientesLiquidacionComoSeleccionados = function () {
               if ($scope.seleccionarTodosLosExpedientesLiquidacion) {
                   $scope.seleccionarTodosLosExpedientesLiquidacion = false;
               } else {
                   $scope.seleccionarTodosLosExpedientesLiquidacion = true;
               }
               angular.forEach($scope.expedientesPorAsignacion, function (expediente) {
                   expediente.Selected = $scope.seleccionarTodosLosExpedientesLiquidacion;
               });
           };

           $scope.generarDocumentacionLiquidacion = function () {
               //Inicio Iker
               $scope.FechaHoraConvocatoria = Date.now();
               var doc = $scope.DocumentoSeleccionado;
               var idsExpedientes = '';
               var blnExpedientesSeleccionados = false;
               var asignacion = '';
               angular.forEach($scope.expedientesPorAsignacion, function (expediente) {
                   if (expediente.Selected) {
                       idsExpedientes += expediente.Id + ",";
                       blnExpedientesSeleccionados = true;
                       asignacion = expediente.AsignacionExpediente;
                   }
               });

               if (!blnExpedientesSeleccionados) {
                   alert('No se ha seleccionado ning�n expediente');
                   return;
               }

               if (idsExpedientes.length > 0)
                   idsExpedientes = idsExpedientes.substring(0, idsExpedientes.length - 1);

               expedientesService.getDocumentos(doc, idsExpedientes, 0, Date.now(), $scope.FechaHoraConvocatoria, asignacion)
                    .success(function (data) {
                        $scope.documentoObtenido = data;
                        var filename = doc + '.zip';
                        var contentType = 'application/octet-stream';
                        if (idsExpedientes.indexOf(",") >= 0) {
                            filename = doc + '.zip';
                            contentType = 'application/octet-stream';
                        }

                        var blob = new Blob([data],
                        { type: contentType });

                        if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                            window.navigator.msSaveBlob(blob, filename);
                        else //Resto
                        {
                            var downloadLink = document.createElement("a");
                            downloadLink.setAttribute("target", "_blank");
                            downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                            downloadLink.setAttribute('download', filename);
                            document.body.appendChild(downloadLink);
                            downloadLink.click();
                            document.body.removeChild(downloadLink);
                        }
                        $('#myModalSeleccionPropietariosOficios').limpiarControlesSeleccionInforme;
                        $('#myModalSeleccionDocumentacion').modal('hide');
                    })
                     .error(function (response) {
                         //$('#myModalSeleccionPropietariosOficios').limpiarControlesSeleccionInforme;
                         limpiarControlesSeleccionInforme;
                         $('#myModalSeleccionExpedientes').modal('hide');
                         alert('Error al generar los documentos.');
                     });
           };
         
           $scope.limpiarControlesSeleccionInforme = function () {
               //$scope.FechaEspecificaInforme = null;
               //$scope.HoraEspecificaInforme = null;
               $scope.FechaHoraConvocatoria = null;
               $scope.EstadoInformeId = null;
               $scope.asignacionExpediente.AsignacionExpediente = null;
           };


           $scope.limpiarControlesPropietariosCitacionAP = function () {
               $scope.TerminoMunicipalId = null;
               $scope.asignacionexpedientefiltro = null;
           };

           $scope.limpiarControlesRelacionPago = function () {
               $scope.TerminoMunicipalId = null;
               $scope.asignacionexpedientefiltro = null;
           };

           $scope.limpiarControlesSeleccionOficios = function () {
               $scope.EstadoInformeId = null;
               $scope.propietario.Id = null;
               $scope.terminomunicipal.Id = null;
           };



           $scope.seleccioonarTerminoMunicipalRelacionPago = function () {
               $scope.asignacionexpedientefiltro = "";
               $scope.asignacionexpedientes = [];
               angular.forEach($scope.claveInforme.TerminosMunicipales, function (terminoMunicipal) {
                   if(terminoMunicipal.Id == $scope.TerminoMunicipalId)
                       $scope.asignacionexpedientes = terminoMunicipal.AsignacionesExpedientes;
               });
           };

           $scope.seleccionarTerminoMunicipalPropietariosCitacion = function () {
               $scope.estadopropietarioscitacionfiltro = "";
               //$scope.estadospropietarioscitacion = [];
               $scope.estadospropietarioscitacion = ["AP", "DP", "AO", "MA"];
               angular.forEach($scope.claveInforme.TerminosMunicipales, function (terminoMunicipal) {
                   if (terminoMunicipal.Id == $scope.TerminoMunicipalId)                       
                       $scope.estadospropietarioscitacion = terminoMunicipal.EstadosPropietariosCitacion;
               });
           };

           $scope.getDocumentoRelacionPago = function (doc) {
               if ($scope.NombreDocumento == "RelacionPago"){
                   expedientesService.getDocumentoRelacionPago(doc, $scope.claveInforme.Id, $scope.TerminoMunicipalId, $scope.asignacionexpedientefiltro, 0, 0)
                     .success(function (data) {
                         $scope.documentoObtenido = data;

                         angular.forEach($scope.claveInforme.TerminosMunicipales, function (terminoMunicipal) {
                             if (terminoMunicipal.Id == $scope.TerminoMunicipalId)
                                 doc += '_' + terminoMunicipal.Municipio + '_' + $scope.asignacionexpedientefiltro;
                         });

                         var filename = doc + '.docx';
                         var contentType = 'application/msword';

                         var blob = new Blob([data],
                         { type: contentType });

                         if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                             window.navigator.msSaveBlob(blob, filename);
                         else //Resto
                         {
                             var downloadLink = document.createElement("a");
                             downloadLink.setAttribute("target", "_blank");
                             downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                             downloadLink.setAttribute('download', filename);
                             document.body.appendChild(downloadLink);
                             downloadLink.click();
                             document.body.removeChild(downloadLink);
                         }
                     })
                      .error(function (response) {
                          alert('Error al generar documento.');
                      });
               }
               

               if ($scope.NombreDocumento == "ListadoFirmasRecibis") {

                   expedientesService.getDocumentoFirmasRecibis(doc, $scope.claveInforme.Id, $scope.TerminoMunicipalId, $scope.asignacionexpedientefiltro, 0, 0)
                     .success(function (data) {
                         $scope.documentoObtenido = data;

                         angular.forEach($scope.claveInforme.TerminosMunicipales, function (terminoMunicipal) {
                             if (terminoMunicipal.Id == $scope.TerminoMunicipalId)
                                 doc += '_' + terminoMunicipal.Municipio + '_' + $scope.asignacionexpedientefiltro;
                         });

                         var filename = doc + '.docx';
                         var contentType = 'application/msword';

                         var blob = new Blob([data],
                         { type: contentType });

                         if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                             window.navigator.msSaveBlob(blob, filename);
                         else //Resto
                         {
                             var downloadLink = document.createElement("a");
                             downloadLink.setAttribute("target", "_blank");
                             downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                             downloadLink.setAttribute('download', filename);
                             document.body.appendChild(downloadLink);
                             downloadLink.click();
                             document.body.removeChild(downloadLink);
                         }
                     })
                      .error(function (response) {
                          alert('Error al generar documento.');
                      });
               }

               if ($scope.NombreDocumento == "ListadoPropietariosCitacion") {
                  
                       expedientesService.getDocumentoPropietariosCitacion(doc, $scope.claveInforme.Id, $scope.TerminoMunicipalId, $scope.estadospropietarioscitacion, 0, 0)
                         .success(function (data) {
                             $scope.documentoObtenido = data;

                             angular.forEach($scope.claveInforme.TerminosMunicipales, function (terminoMunicipal) {
                                 if (terminoMunicipal.Id == $scope.TerminoMunicipalId)
                                     doc += '_' + terminoMunicipal.Municipio + '_' + $scope.asignacionexpedientefiltro;
                             });

                             var filename = doc + '.docx';
                             var contentType = 'application/msword';

                             var blob = new Blob([data],
                             { type: contentType });

                             if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                                 window.navigator.msSaveBlob(blob, filename);
                             else //Resto
                             {
                                 var downloadLink = document.createElement("a");
                                 downloadLink.setAttribute("target", "_blank");
                                 downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                                 downloadLink.setAttribute('download', filename);
                                 document.body.appendChild(downloadLink);
                                 downloadLink.click();
                                 document.body.removeChild(downloadLink);
                             }
                         })
                          .error(function (response) {
                              alert('Error al generar documento.');
                          });

                }
           };

           $scope.FechaAnadir1200 = function (fecha) {
               if (fecha != null && fecha != "" && fecha != undefined)
                   return new Date(fecha.setHours(12, 0));

               return null;
           }
           //Fin Iker

           //Obtener MDB
           $scope.ObtenerMDBExproecon = function () {
               expedientesService.getMdbExproecon($routeParams.id)
                                 .success(function (data) {
                                     $scope.documentoObtenido = data;
                                     var filename = 'ExpropiacionesDatos_Web.mdb';
                                     contentType = 'application/octet-stream';

                                     var blob = new Blob([data],
                                     { type: contentType });

                                     if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                                         window.navigator.msSaveBlob(blob, filename);
                                     else //Resto
                                     {
                                         var downloadLink = document.createElement("a");
                                         downloadLink.setAttribute("target", "_blank");
                                         downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                                         downloadLink.setAttribute('download', filename);
                                         document.body.appendChild(downloadLink);
                                         downloadLink.click();
                                         document.body.removeChild(downloadLink);
                                     }
                                 })
                                  .error(function (response) {
                                      alert('Error al generar la base de datos.');
                                  });
           }
           //Fin Obtener MDB
       }]);