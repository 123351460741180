angular.module('controlexpedientes', [])
       .controller('controlexpedientesController', ['$scope', '$location', '$route', '$filter', '$routeParams', 'onlineStatus', 'authenticationInfo', 'controlexpedientesService', function ($scope, $location, $route, $filter, $routeParams, onlineStatus, authenticationInfo, controlexpedientesService) {


           $scope.onlineStatus = onlineStatus;
           $scope.authentication = authenticationInfo;
           $scope.$watch('onlineStatus.isOnline()', function (online) {
               $scope.online_status_string = online ? 'online' : 'offline';
               $scope.estado = $scope.online_status_string;
           });

           $scope.clavefiltro = $routeParams.idClave;
           $scope.terminomunicipalfiltro = $routeParams.idTerminoMunicipal;
           $scope.asignacionexpedientefiltro = 0;

           controlexpedientesService.getClaves()
                  .success(function (data) {
                      $scope.claves = data;
                  });

           controlexpedientesService.getClasesPagoExpediente()
                   .success(function (data) {
                       $scope.clasespagoexpediente = data;
                   });

           $scope.terminosmunicipales = [];
           $scope.asignacionexpedientes = [];
           $scope.calculoInteresesMostrar = false;
           $scope.TextoFechaInicioIntereses = "Fecha Inicio Intereses";
           $scope.FincasI = null;
           $scope.NumFincasI = null;
           $scope.ImporteI = null;
           //$scope.FechaPagoExpedienteI = null;
           $scope.FechaPagoExpedienteComunI = null;
           $scope.ClaseExpedientePagoIntereses = null;

           $scope.InteresesGuardados = false;

           $scope.$watch('clavefiltro', function () {
               $scope.terminomunicipalfiltro = "";
               $scope.asignacionexpedientefiltro = "";
               $scope.pagoexpediente = null;
               var claveSeleccionada = $filter('filter')($scope.claves, { Id: parseInt($scope.clavefiltro) }, true)[0];
               if (claveSeleccionada != null) {
                   $scope.terminosmunicipales = claveSeleccionada.TerminosMunicipales;
               } else {
                   $scope.terminosmunicipales = [];
               }
               $scope.asignacionexpedientes = [];
           });
           $scope.$watch('terminomunicipalfiltro', function () {
               $scope.asignacionexpedientefiltro = "";
               $scope.pagoexpediente = null;
               var claveSeleccionada = $filter('filter')($scope.claves, { Id: parseInt($scope.clavefiltro) }, true)[0];
               var terminoMunicipalSeleccionado = $filter('filter')(claveSeleccionada.TerminosMunicipales, { Id: parseInt($scope.terminomunicipalfiltro) }, true)[0];
               if (terminoMunicipalSeleccionado != null) {
                   $scope.asignacionexpedientes = terminoMunicipalSeleccionado.AsignacionesExpedientes;
               } else {
                   $scope.asignacionexpedientes = [];
               }
           });
           $scope.$watch('asignacionexpedientefiltro', function () {
               $scope.pagoexpediente = null;
               if ($scope.asignacionexpedientefiltro != null && $scope.asignacionexpedientefiltro != "") {
                   controlexpedientesService.getPagoExpediente($scope.clavefiltro, $scope.terminomunicipalfiltro, $scope.asignacionexpedientefiltro)
                           .success(function (data) {
                               $scope.pagoexpediente = data;
                               $scope.pagoexpediente.ImporteServiciosTecnicos = $scope.pagoexpediente.Importe
                               $scope.FechasExpedienteConvertirUTCALocal();
                               $scope.firmasCargar();

                               if ($scope.pagoexpediente.HoraPagoExpediente != null && $scope.pagoexpediente.HoraPagoExpediente != "") {
                                   var horaDate = new Date("1900-01-01T" + $scope.pagoexpediente.HoraPagoExpediente);
                                   $scope.pagoexpediente.HoraPagoExpediente = horaDate;
                               }
                               //*********************************************
                               //¡¡¡¡¡¡¡¡
                               //Mirar si hay que coger fincas de expedientes cuya Asignación Expediente sea la seleccionada.
                               //!!!!!!!!
                               //*********************************************
                               if ($scope.FincasI != null) {
                                   $scope.pagoexpediente.Fincas = $scope.FincasI;
                                   $scope.pagoexpediente.NumFincas = $scope.NumFincasI;
                                   $scope.pagoexpediente.Importe = $scope.ImporteI;

                                   //  Comento esta linea para que salga vacia la fecha de pago de expediente tal y como solicita Emilio a 12/09/2018
                                   // $scope.pagoexpediente.FechaPagoExpediente = new Date(); //$scope.FechaPagoExpedienteI;

                                   //$scope.pagoexpediente.FechaPagoExpediente = $scope.FechaPagoExpedienteI;
                                   $scope.pagoexpediente.FechaPagoExpedienteComun = $scope.FechaPagoExpedienteComunI;

                                   $scope.ClaseExpedientePagoIntereses = 1;
                                   $scope.pagoexpediente.ClaseExpedientePagoId = 5;
                                   $scope.pagoexpediente.FechaPagoIntereses = undefined //new Date();
                                   $scope.FincasI = null;
                                   $scope.NumFincasI = null;
                                   $scope.ImporteI = null;
                                   //$scope.FechaPagoExpedienteI = null;
                                   $scope.FechaPagoExpedienteComunI = null;
                               }
                           });

                   if ($scope.asignacionexpedientefiltro.substring(0, 2) == "II")
                       $scope.TextoFechaInicioIntereses = "Fecha Inicio Intereses de Intereses";
                   else
                       $scope.TextoFechaInicioIntereses = "Fecha Inicio Intereses";

               }
           });

           $scope.$watch('pagoexpediente.ClaseExpedientePagoId', function () {
               //Opción 5: Intereses
               var asigExpISeleccionado = ($scope.asignacionexpedientefiltro == null) ? false : (($scope.asignacionexpedientefiltro == "") ? false : (($scope.asignacionexpedientefiltro.toString().substr(0, 1) == "I") ? true : false));
               if ($scope.pagoexpediente != null && $scope.pagoexpediente.ClaseExpedientePagoId == 5 && $scope.ClaseExpedientePagoIntereses == null && asigExpISeleccionado == false) {
                   $scope.FincasI = $scope.pagoexpediente.Fincas;
                   $scope.NumFincasI = $scope.pagoexpediente.NumFincas;
                   $scope.ImporteI = $scope.pagoexpediente.Importe;
                   //$scope.FechaPagoExpedienteI = $scope.pagoexpediente.FechaPagoExpediente;
                   $scope.FechaPagoExpedienteComunI = $scope.pagoexpediente.FechaPagoExpediente;

                   try {
                       var asigExp = $filter('filter')($scope.asignacionexpedientes, "I" + $scope.asignacionexpedientefiltro, true)[0];
                       if (asigExp == null) $scope.asignacionexpedientes.push("I" + $scope.asignacionexpedientefiltro);
                   } catch (e) {

                   }
                   $scope.asignacionexpedientefiltro = "I" + $scope.asignacionexpedientefiltro;

                   if ($scope.asignacionexpedientefiltro.substring(0, 2) == "II")
                       $scope.TextoFechaInicioIntereses = "Fecha Inicio Intereses de Intereses";
                   else
                       $scope.TextoFechaInicioIntereses = "Fecha Inicio Intereses";
               }
               if ($scope.pagoexpediente.ClaseExpedientePagoId == 5) {
                   $scope.calculoInteresesMostrar = true;
               } else {
                   $scope.calculoInteresesMostrar = false;
               }
               $scope.ClaseExpedientePagoIntereses = null;
           });

           $scope.interesesCalcular = function () {
               $scope.FechasExpedienteAnadir1200();
               //Tiene que haber fecha pago y fecha de inicio de intereses para todas las fincas. La fecha de pago debe ser posterior a todas las fechas de inicio de intereses.

               var fincasSeleccionadas = $scope.pagoexpediente.Fincas.filter(function (finca) {
                   return finca.selected;
               });

               var todoOk = true;

               if (fincasSeleccionadas.length == 0) {
                   todoOk = false;
                   alert('No hay fincas seleccionadas');
               }
                   //else if ($scope.pagoexpediente.FechaPagoComun == null || $scope.pagoexpediente.FechaPagoComun == '') {
                   //else if ($scope.pagoexpediente.FechaPagoExpedienteComun == null || $scope.pagoexpediente.FechaPagoExpedienteComun == '') {
                   //    todoOk = false;
                   //    alert('Falta la fecha de pago.');
                   //    } 
               else {
                   for (var i = 0; i < $scope.pagoexpediente.Fincas.length; i++) {
                       //finca.InicioIntereses
                       if ($scope.pagoexpediente.Fincas[i].InicioIntereses == null || $scope.pagoexpediente.Fincas[i].InicioIntereses == '') {
                           todoOk = false;
                           alert('Falta la fecha de inicio de intereses.');
                           break;
                       }

                       if ($scope.pagoexpediente.Fincas[i].FechaPagoExpediente == null || $scope.pagoexpediente.Fincas[i].FechaPagoExpediente == '') {
                           todoOk = false;
                           alert('Falta la fecha de pago.');
                           break;
                       }

                       var fechaInicioInteresesFinca = new Date($scope.pagoexpediente.Fincas[i].InicioIntereses);
                       var fechaPagoExpedienteFinca = new Date($scope.pagoexpediente.Fincas[i].FechaPagoExpediente);
                       //var fechaPagoExpediente = new Date($scope.pagoexpediente.FechaPagoExpediente);
                       if (fechaInicioInteresesFinca >= fechaPagoExpedienteFinca) {
                           todoOk = false;
                           alert('La fecha de inicio de intereses de cada finca debe ser anterior a la fecha de pago.');
                           break;
                       }
                   }
               }
               if (todoOk) {
                   $scope.pagoexpediente.Fincas = fincasSeleccionadas;
                   angular.forEach($scope.pagoexpediente.Fincas, function (finca) {
                       finca.InicioIntereses = $scope.FechaAnadir1200(new Date(finca.InicioIntereses));
                   })

                   angular.forEach($scope.pagoexpediente.Fincas, function (finca) {
                       finca.FechaPagoExpediente = $scope.FechaAnadir1200(new Date(finca.FechaPagoExpediente));
                   })

                   controlexpedientesService.interesesCalcular($scope.clavefiltro, $scope.terminomunicipalfiltro, $scope.asignacionexpedientefiltro, $scope.pagoexpediente)
                       .success(function (data) {
                           controlexpedientesService.getPagoExpediente($scope.clavefiltro, $scope.terminomunicipalfiltro, $scope.asignacionexpedientefiltro)
                                   .success(function (data) {
                                       $scope.pagoexpediente = data;
                                       $scope.pagoexpediente.ImporteServiciosTecnicos = $scope.pagoexpediente.Importe
                                       $scope.FechasExpedienteConvertirUTCALocal();
                                       $scope.firmasCargar();
                                       if ($scope.pagoexpediente.HoraPagoExpediente != "") {
                                           var horaDate = new Date("1900-01-01T" + $scope.pagoexpediente.HoraPagoExpediente);
                                           $scope.$apply($scope.pagoexpediente.HoraPagoExpediente = horaDate);
                                       }
                                   });
                           // $scope.pagoexpediente.ImporteIntereses = "";
                           $scope.pagoexpediente.ImporteIntereses = data;

                           $scope.InteresesGuardados = true;
                           alert('Intereses calculados correctamente');
                       })
                   .error(function (response) {
                       $scope.error = controlexpedientesService.obtenerPrimerErrorModelState(response);
                       alert($scope.error);
                   });
               }
           };

           $scope.interesesDeInteresesCalcular = function () {
               if ($scope.pagoexpediente.FechaPagoIntereses == null || $scope.pagoexpediente.FechaPagoIntereses == '') {
                   alert('Falta informar y guardar la fecha de pago de intereses.');
               } else {
                   try {
                       var asigExp = $filter('filter')($scope.asignacionexpedientes, "I" + $scope.asignacionexpedientefiltro, true)[0];
                       if (asigExp == null) $scope.asignacionexpedientes.push("I" + $scope.asignacionexpedientefiltro);
                   } catch (e) {

                   }
                   $scope.asignacionexpedientefiltro = "I" + $scope.asignacionexpedientefiltro;

                   $scope.InicioInteresesComun = "";

                   if ($scope.asignacionexpedientefiltro.substring(0, 2) == "II")
                       $scope.TextoFechaInicioIntereses = "Fecha Inicio Intereses de Intereses";
                   else
                       $scope.TextoFechaInicioIntereses = "Fecha Inicio Intereses";
               }
           }

           //Impresos
           $scope.seleccionarImpreso = function (opcion) {
               $scope.ImpresoSeleccionado = opcion;
               if (opcion != 4 && opcion != 5)
                   $scope.impresos();
           }

           $scope.impresos = function () {
               $('#myModalSeleccionImpresos').modal('hide');
               var doc = "";
               switch ($scope.ImpresoSeleccionado) {
                   case 1:
                       doc = "Remision";
                       break;
                   case 2:
                       doc = "Caratula";
                       break;
                   case 3:
                       doc = "Liquidacion";
                       break;
                   case 4:
                       doc = "Citaciones";
                       break;
                   case 5:
                       doc = "SolicitudTransferencia";
                       break;
                   case 6:
                       doc = "RelacionPago";
                       break;
                   case 7:
                       doc = "Resumen";
                       break;
                   case 8:
                       doc = "Memoria";
                       break;
                   case 9:
                       doc = "ProcedimientoUrgencia";
                       break;
                   case 10:
                       doc = "Propuesta";
                       break;
                   default:
                       break;
               }

               if ($scope.asignacionexpedientefiltro != "") {
                   var importe = ($scope.asignacionexpedientefiltro.toString().substr(0, 1) == "I") ? $scope.pagoexpediente.ImporteIntereses : $scope.pagoexpediente.ImporteServiciosTecnicos;
                   importe = importe == "" ? "0" : importe;
                   controlexpedientesService.getDocumento(doc, $scope.clavefiltro, $scope.terminomunicipalfiltro, $scope.asignacionexpedientefiltro, importe, $scope.pagoexpediente.NumFincas)
                     .success(function (data) {
                         $scope.documentoObtenido = data;
                         var filename = doc + '.docx';
                         var contentType = 'application/msword';

                         var blob = new Blob([data],
                         { type: contentType });

                         if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                             window.navigator.msSaveBlob(blob, filename);
                         else //Resto
                         {
                             var downloadLink = document.createElement("a");
                             downloadLink.setAttribute("target", "_blank");
                             downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                             downloadLink.setAttribute('download', filename);
                             document.body.appendChild(downloadLink);
                             downloadLink.click();
                             document.body.removeChild(downloadLink);
                         }
                     })
                      .error(function (response) {
                          alert('Error al generar documento.');
                      });
               }
               $scope.ImpresoSeleccionado = null;
           }
           //Fin Impresos

           //Listados
           $scope.seleccionarListado = function (opcion) {
               $scope.ListadoSeleccionado = opcion;
               $scope.listados();
           }

           $scope.listados = function () {
               $('#myModalSeleccionListados').modal('hide');

               var listado = "";
               switch ($scope.ListadoSeleccionado) {
                   case 1:
                       listado = "ObligacionesPendientes";
                       break;
                   case 2:
                       listado = "Libramientos";
                       break;
                   default:
                       break;
               }

               /*controlexpedientesService.getListado($scope.ListadoSeleccionado)
                     .success(function (data, headers) {
                         var filename = listado + ".xls";
                         var contentType = 'application/vnd.ms-excel';

                         var blob = new Blob([data],
                         { type: contentType });
                         if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                             window.navigator.msSaveBlob(blob, filename);
                         else //Resto
                         {
                             var downloadLink = document.createElement("a");
                             var url = window.URL.createObjectURL(blob);
                             downloadLink.setAttribute("target", "_blank");
                             downloadLink.setAttribute("href", url);
                             downloadLink.setAttribute("download", filename);
                             document.body.appendChild(downloadLink);
                             downloadLink.click();
                             document.body.removeChild(downloadLink);
                         }
                     })
                     .error(function (response) {
                         $scope.error = expedientesService.obtenerPrimerErrorModelState(response);
                         alert('Error al obtener el listado.');
                     });*/

               $scope.ListadoSeleccionado = null;
           }
           //Fin Listados


           $scope.guardar = function () {
               $scope.FechasExpedienteAnadir1200();
               controlexpedientesService.guardar($scope.clavefiltro, $scope.terminomunicipalfiltro, $scope.asignacionexpedientefiltro, $scope.pagoexpediente)
                    .success(function (data) {
                        if ($scope.pagoexpediente.Id <= 0) {
                            //$scope.pagoexpediente.Id = data;
                            controlexpedientesService.getPagoExpediente($scope.clavefiltro, $scope.terminomunicipalfiltro, $scope.asignacionexpedientefiltro)
                                    .success(function (data) {
                                        $scope.pagoexpediente = data;
                                        $scope.pagoexpediente.ImporteServiciosTecnicos = $scope.pagoexpediente.Importe
                                        $scope.FechasExpedienteConvertirUTCALocal();
                                        $scope.firmasCargar();

                                        if ($scope.pagoexpediente.HoraPagoExpediente != "") {
                                            horaDate = new Date("1900-01-01");
                                            horaDate.setHours($scope.pagoexpediente.HoraPagoExpediente.substr(0, $scope.pagoexpediente.HoraPagoExpediente.indexOf(":")));
                                            horaDate.setMinutes($scope.pagoexpediente.HoraPagoExpediente.substr($scope.pagoexpediente.HoraPagoExpediente.indexOf(":") + 1, $scope.pagoexpediente.HoraPagoExpediente.length - ($scope.pagoexpediente.HoraPagoExpediente.indexOf(":") + 1)));
                                            $scope.$apply($scope.pagoexpediente.HoraPagoExpediente = horaDate);
                                        }
                                    });
                        }
                        alert('Datos guardados correctamente');
                    })
                .error(function (response) {
                    $scope.error = controlexpedientesService.obtenerPrimerErrorModelState(response);
                    alert($scope.error);
                });
           }

           $scope.FechasExpedienteConvertirUTCALocal = function () {
               $scope.pagoexpediente.FechaInformeServiciosTecnicos = $scope.FechaConvertirUTCALocal($scope.pagoexpediente.FechaInformeServiciosTecnicos);
               $scope.pagoexpediente.FechaEnvio = $scope.FechaConvertirUTCALocal($scope.pagoexpediente.FechaEnvio);
               $scope.pagoexpediente.FechaAprobacion = $scope.FechaConvertirUTCALocal($scope.pagoexpediente.FechaAprobacion);
               //$scope.pagoexpediente.FechaPagoExpediente = $scope.FechaConvertirUTCALocal($scope.pagoexpediente.FechaPagoExpediente);
               $scope.pagoexpediente.FechaPagoExpedienteComun = $scope.FechaConvertirUTCALocal($scope.pagoexpediente.FechaPagoExpedienteComun);
               $scope.pagoexpediente.FechaPagoIntereses = $scope.FechaConvertirUTCALocal($scope.pagoexpediente.FechaPagoIntereses);
               $scope.pagoexpediente.FechaSesionJurado = $scope.FechaConvertirUTCALocal($scope.pagoexpediente.FechaSesionJurado);
               $scope.pagoexpediente.FechaPresupuestoGastoJurado = $scope.FechaConvertirUTCALocal($scope.pagoexpediente.FechaPresupuestoGastoJurado);


               if ($scope.pagoexpediente.Fincas != null) {
                   angular.forEach($scope.pagoexpediente.Fincas, function (finca) {
                       finca.LevantamientoAP = $scope.FechaConvertirUTCALocal(finca.LevantamientoAP);
                       finca.ActaMA = $scope.FechaConvertirUTCALocal(finca.ActaMA);
                       finca.Ocupacion = $scope.FechaConvertirUTCALocal(finca.Ocupacion);
                   })
               }
           }

           $scope.FechaConvertirUTCALocal = function (fecha) {
               if (fecha != null && fecha != "" && fecha != undefined)
                   return new Date(fecha + "Z");

               return undefined;
           }

           $scope.FechasExpedienteAnadir1200 = function () {
               $scope.pagoexpediente.FechaInformeServiciosTecnicos = $scope.FechaAnadir1200($scope.pagoexpediente.FechaInformeServiciosTecnicos);
               $scope.pagoexpediente.FechaEnvio = $scope.FechaAnadir1200($scope.pagoexpediente.FechaEnvio);
               $scope.pagoexpediente.FechaAprobacion = $scope.FechaAnadir1200($scope.pagoexpediente.FechaAprobacion);
               //$scope.pagoexpediente.FechaPagoExpediente = $scope.FechaAnadir1200($scope.pagoexpediente.FechaPagoExpediente);
               $scope.pagoexpediente.FechaPagoExpedienteComun = $scope.FechaAnadir1200($scope.pagoexpediente.FechaPagoExpedienteComun);
               $scope.pagoexpediente.FechaPagoIntereses = $scope.FechaAnadir1200($scope.pagoexpediente.FechaPagoIntereses);
               $scope.pagoexpediente.FechaSesionJurado = $scope.FechaAnadir1200($scope.pagoexpediente.FechaSesionJurado);
               $scope.pagoexpediente.FechaPresupuestoGastoJurado = $scope.FechaAnadir1200($scope.pagoexpediente.FechaPresupuestoGastoJurado);

               if ($scope.pagoexpediente.Fincas != null) {
                   angular.forEach($scope.pagoexpediente.Fincas, function (finca) {
                       finca.LevantamientoAP = $scope.FechaAnadir1200(finca.LevantamientoAP);
                       finca.ActaMA = $scope.FechaAnadir1200(finca.ActaMA);
                       finca.Ocupacion = $scope.FechaAnadir1200(finca.Ocupacion);
                   })
               }
           }

           $scope.FechaAnadir1200 = function (fecha) {
               //if (fecha != null && fecha != "" && fecha != undefined && typeof (fecha) != "string") {
               if (fecha != null && fecha != "" && fecha != undefined) {
                   if (typeof (fecha) == "string") {
                       var aux = new Date(fecha);
                       var fecha1 = aux.setHours(12, 0, 0);
                       return aux;
                   } else {
                       var aux = fecha;
                       var fecha1 = aux.setHours(12, 0, 0);
                       return aux;
                   }
               }
               return null;
           }

           $scope.firmasCargar = function () {
               controlexpedientesService.getFirmas($scope.clavefiltro, $scope.terminomunicipalfiltro)
                      .success(function (data) {
                          $scope.firmas = data;
                      });
           }

           $scope.generarDocumentacionEspecial = function () {
               var doc = "CitacionPago";
               if ($scope.ImpresoSeleccionado == 5) {
                   doc = "NotificacionTransferencia";
                   $scope.HoraPagoImpresos = new Date();
                   $scope.LugarPagoImpresos = "";
               }

               $scope.FechaPagoImpresos = $scope.FechaAnadir1200($scope.FechaPagoImpresos);

               controlexpedientesService.getCitacionNotificacion($scope.clavefiltro, $scope.terminomunicipalfiltro,
                                                                $scope.asignacionexpedientefiltro,
                                                                $scope.AsuntoImpresos,
                                                                $scope.FechaPagoImpresos,
                                                                $scope.HoraPagoImpresos,
                                                                $scope.LugarPagoImpresos,
                                                                $scope.FirmaId,
                                                                $scope.ImpresoSeleccionado)
                .success(function (data) {
                    $scope.documentoObtenido = data;
                    var filename = doc + '.zip';
                    var contentType = 'application/octet-stream';

                    var blob = new Blob([data],
                    { type: contentType });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                        window.navigator.msSaveBlob(blob, filename);
                    else //Resto
                    {
                        var downloadLink = document.createElement("a");
                        downloadLink.setAttribute("target", "_blank");
                        downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                        downloadLink.setAttribute('download', filename);
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    }
                })
                 .error(function (response) {
                     alert('Error al generar documento.');
                 });

           }

           $scope.InicioInteresesComun = "";
           $scope.fechasInicioCambiar = function () {
               angular.forEach($scope.pagoexpediente.Fincas, function (finca) {
                   finca.InicioIntereses = $scope.FechaAnadir1200($scope.InicioInteresesComun);
               })
               $scope.InteresesGuardados = false;
           }

           $scope.FechaPagoExpedienteComun = "";
           $scope.fechasPagoExpedienteCambiar = function () {
               angular.forEach($scope.pagoexpediente.Fincas, function (finca) {
                   //if ($scope.pagoexpediente.FechaPagoExpedienteComun != '' || $scope.pagoexpediente.FechaPagoExpedienteComun != null)
                   //    finca.FechaPagoExpediente = $scope.FechaAnadir1200($scope.pagoexpediente.FechaPagoExpedienteComun)
                   //else
                   //finca.FechaPagoExpediente = $scope.FechaAnadir1200($scope.FechaPagoExpedienteComun);
                   finca.FechaPagoExpediente = $scope.FechaAnadir1200($scope.pagoexpediente.FechaPagoExpediente);
               })
               $scope.InteresesGuardados = false;
           }

           //$scope.addDays = function (date, days) {
           //    var result = new Date(date);
           //    result.setDate(result.getDate() + days);
           //    return result;
           //}

           $scope.eliminar = function () {
               controlexpedientesService.borrar($scope.clavefiltro, $scope.terminomunicipalfiltro, $scope.asignacionexpedientefiltro)
                    .success(function (data) {
                        $scope.clavefiltro = null;
                        controlexpedientesService.getClaves()
                                                   .success(function (data) {
                                                       $scope.claves = data;
                                                   });
                        alert("Pago eliminado correctamente.");
                    });
           }

           $scope.seleccionarTodasLasFincas = false;
           $scope.marcarTodasLasFincas = function () {
               var marcar = !$scope.seleccionarTodasLasFincas;
               $scope.pagoexpediente.Fincas.forEach(function (finca) {
                   finca.selected = marcar;
               });
               $scope.seleccionarTodasLasFincas = marcar;
           }
       }]);