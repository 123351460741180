angular.module('claves')
       .controller('claveController', ['$scope', '$routeParams', '$location', 'authenticationInfo', 'clavesService', 'Utilidades', function ($scope, $routeParams, $location, authenticationInfo, clavesService, Utilidades) {
           $scope.authentication = authenticationInfo;
           $scope.dateOptions = Utilidades.getDatePickerOptions();

           $scope.idClave = $routeParams.id;

           clavesService.getCarreteras()
                             .success(function (data) {
                                 $scope.carreteras = data;
                             });
           clavesService.getAutoridades()
                             .success(function (data) {
                                 $scope.autoridades = data;
                                 //$scope.autoridadesIniciales = angular.copy($scope.autoridades);
                             });
           clavesService.getTerminosMunicipales()
                             .success(function (data) {
                                 $scope.terminosMunicipales = data;
                             });
           clavesService.getClavesAsociar($scope.idClave)
                             .success(function (data) {
                                 $scope.claves = data;
                             });
           clavesService.getTiposObra()
                             .success(function (data) {
                                 $scope.tiposobra = data;
                             });
           clavesService.getTiposvia()
                             .success(function (data) {
                                 $scope.tiposvia = data;
                             });
           clavesService.getLegislacionesAplicables()
                             .success(function (data) {
                                 $scope.legislacionesaplicables = data;
                             });
           clavesService.getLeyesAcompañamiento()
                             .success(function (data) {
                                 $scope.leyesacomp = data;
                             });
           clavesService.getClave($scope.idClave)
                             .success(function (data) {
                                 $scope.clave = data;
                                 $scope.FechasExpedienteConvertirUTCALocal();
                             });

           $scope.volver = function () {
               $location.path('/claves');
           }

           /*$scope.nuevaAutoridad = function () {
               var id = $scope.autoridades.length - $scope.autoridadesIniciales.length - 1;
               $scope.autoridades.push({ Id: id, Descripcion: '' });
               $scope.$apply();
           }
           $scope.guardarAutoridades = function () {
                var autoridadesGuardar = [];
                for (var i = 0; i < $scope.autoridadesIniciales.length; i++) {
                    if ($scope.autoridadesIniciales[i].Descripcion != $scope.autoridades[i].Descripcion) {
                        autoridadesGuardar.push($scope.autoridades[i]);
                    }
                }
                for (var j = i; j < $scope.autoridades.length; j++) {
                    if ($scope.autoridades[i].Descripcion!='') {
                        autoridadesGuardar.push($scope.autoridades[i]);
                    }
                }
                if (autoridadesGuardar.length>0){
                    clavesService.autoridadesPut(autoridadesGuardar)
                                .success(function (data) {
                                    clavesService.getAutoridades()
                                                      .success(function (data) {
                                                          $scope.autoridades = data;
                                                          $scope.autoridadesIniciales = angular.copy($scope.autoridades);
                                                      });
                                })
                            .error(function (response) {
                                $scope.error = supervisionesService.obtenerPrimerErrorModelState(response);
                                alert($scope.error);
                                $scope.autoridades = angular.copy($scope.autoridadesIniciales);
                            });
                }
               $('#myModalAutoridades').modal('hide');
           };*/

           $scope.seleccionarCarretera = function (carretera) {
               if ($scope.clave.Carreteras == null) $scope.clave.Carreteras = [];
               $scope.clave.Carreteras.push(carretera);
               $('#myModalCarreteras').modal('hide');
           }
           $scope.eliminarCarretera = function (carretera) {
               var index = $scope.clave.Carreteras.indexOf(carretera);
               $scope.clave.Carreteras.splice(index, 1);
           }

           $scope.seleccionarTerminoMunicipal = function (terminoMunicipal) {
               if ($scope.clave.Municipios == null) $scope.clave.Municipios = [];
               $scope.clave.Municipios.push(terminoMunicipal);
               $('#myModalTerminosMunicipales').modal('hide');
           }
           $scope.eliminarMunicipio = function (municipio) {
               var index = $scope.clave.Municipios.indexOf(municipio);
               $scope.clave.Municipios.splice(index, 1);
           }

           //Cuadro de Precios
           clavesService.getElementosCuadroPreciosClave($scope.idClave)
                             .success(function (data) {
                                 $scope.elementosCuadroPrecios = data;
                             });

           clavesService.getConceptos()
                  .success(function (data) {
                      $scope.conceptos = data;
                  });
           clavesService.getClasificaciones()
                  .success(function (data) {
                      $scope.clasificaciones = data;
                  });
           clavesService.getUnidadesElementos()
                  .success(function (data) {
                      $scope.unidadesElementos = data;
                  });
           $scope.idNuevo = 0;

           $scope.nuevoElementoCuadroPrecios = function () {
               $scope.idNuevo--;
               var elementoCuadroPrecios = {
                   Id: $scope.idNuevo,
                   Descripcion: "",
                   Concepto: "",
                   Clasificacion: "",
                   UnidadesElemento: "",
                   Precio: null
               };
               $scope.elementosCuadroPrecios.push(elementoCuadroPrecios);
           }
           $scope.eliminarElementoCuadroPrecios = function (elementoCuadroPrecios) {
               var index = $scope.elementosCuadroPrecios.indexOf(elementoCuadroPrecios);
               $scope.elementosCuadroPrecios.splice(index, 1);
           }

           $scope.guardarElementosCuadroPreciosClave = function () {
               clavesService.putElementosCuadroPreciosClave($scope.idClave,$scope.elementosCuadroPrecios)
                            .success(function (data) {
                                alert("Datos guardados correctamente");
                                $('#myModalCuadroPreciosClave').modal('hide');
                            })
                           .error(function (response) {
                               $scope.error = clavesService.obtenerPrimerErrorModelState(response);
                               alert($scope.error);
                           });
           }
           //FIN Cuadro de Precios

           //Firmas
           clavesService.getFirmas($scope.idClave)
                             .success(function (data) {
                                 $scope.firmas = data;
                                 angular.forEach($scope.firmas, function (firma) {
                                     if (firma.Genero == "F")
                                         firma.checked = true;
                                 });
                             });

           $scope.guardarFirmasClave = function () {

               angular.forEach($scope.firmas, function (firma) {
                   firma.Genero = "M";
                   if (firma.checked)
                       firma.Genero = "F";
               });

               clavesService.guardarFirmas($scope.firmas)
                            .success(function (data) {
                                alert("Firmas guardadas correctamente");
                                $('#myModalFirmasClave').modal('hide');
                                clavesService.getFirmas($scope.idClave)
                                                    .success(function (data) {
                                                        $scope.firmas = data;
                                                        angular.forEach($scope.firmas, function (firma) {
                                                            if (firma.Genero == "F")
                                                                firma.checked = true;
                                                        });
                                                    });
                            })
                           .error(function (response) {
                               $scope.error = clavesService.obtenerPrimerErrorModelState(response);
                               alert($scope.error);
                           });
           }
           //FIN Firmas

           //Planos
           clavesService.getArchivos($scope.idClave)
            .success(function (data) {
                $scope.archivosClave = data;
            });

           $scope.archivoEliminar = function (idArchivo, indice) {
               clavesService.deleteArchivo(idArchivo)
                                     .success(function (data) {
                                         alert('Archivo eliminado correctamente');
                                         $scope.archivosClave.splice(indice, 1);
                                     })
                    .error(function (response) {
                        $scope.error = clavesService.obtenerPrimerErrorModelState(response);
                        alert($scope.error);
                    });
           }

           $scope.uploadingMultipleFiles = false;
           //$scope.numArchivosMultiplesSeleccionados = '';
           $scope.archivosMultiples = [];
           $scope.formDataArchivosMultiples = new FormData();
           $scope.getFiles = function (file) {
               angular.forEach(file, function (value, key) {
                   $scope.formDataArchivosMultiples.append(key, value);
                   $scope.archivosMultiples.push({ FileName: value.name, FileLength: value.size });

               });
               //$scope.numArchivosMultiplesSeleccionados = $scope.archivosMultiples.length == 0 ? '' : $scope.archivosMultiples.length + ' archivos seleccionados';
               $scope.$apply();
               //$scope.formDataArchivosMultiples.append('countFiles', $scope.numArchivosMultiplesSeleccionados);

           };

           $scope.uploadFiles = function () {
               $scope.uploadingMultipleFiles = true;
               clavesService.postArchivos($scope.idClave , $scope.formDataArchivosMultiples)
                   .then(function (data) {
                       $scope.uploadingMultipleFiles = false;
                       $scope.formDataArchivosMultiples = new FormData();
                       $scope.archivosMultiples = [];
                       //$scope.numArchivosMultiplesSeleccionados = '';
                       $scope.$apply;
                       //Actualizar archivos clave
                       clavesService.getArchivos($scope.idClave)
                           .success(function (data) {
                               $scope.archivosClave = data;
                           })
                           .error(function (response) {
                               $location.path('/claves');
                           });
                       alert("Archivos subidos correctamente");
                   }), function (error) {
                       $scope.uploadingMultipleFiles = false;
                       alert("Error al subir los archivos ");
                   }
                   .error(function (error) {
                       $scope.uploadingMultipleFiles = false;
                       alert("Error al subir los archivos ");
                   });
           };

           $scope.descargarArchivo = function (idArchivo, nombre) {
               clavesService.getArchivo(idArchivo)
                    .success(function (data, headers) {
                        var filename = nombre;
                        var contentType = 'undefined';

                        var blob = new Blob([data],
                        { type: contentType });
                        if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                            window.navigator.msSaveBlob(blob, filename);
                        else //Resto
                        {
                            var downloadLink = angular.element('<a></a>');
                            downloadLink.attr('href', window.URL.createObjectURL(blob));
                            downloadLink.attr('download', filename);
                            downloadLink[0].click();
                        }
                    })
                    .error(function (response) {
                        $scope.error = clavesService.obtenerPrimerErrorModelState(response);
                        alert('Error al obtener el archivo.');
                    });
           }
           //Fin Planos

           $scope.guardarClave = function () {
           //    alert("Antes de añadir 12: " + $scope.clave.FechaOrdenInicioExpediente);
               $scope.FechasExpedienteAnadir1200();
           //    alert("Después de añadir 12: " + $scope.clave.FechaOrdenInicioExpediente);
               if ($scope.idClave > 0) {
                   clavesService.put($scope.clave)
                               .success(function (data) {
                                   alert("Datos guardados correctamente");
                                   clavesService.getFirmas($scope.idClave)
                                                     .success(function (data) {
                                                         $scope.firmas = data;
                                                     });
                               })
                           .error(function (response) {
                               $scope.error = clavesService.obtenerPrimerErrorModelState(response);
                               alert($scope.error);
                           });
               } else {
                   clavesService.post($scope.clave)
                               .success(function (dataId) {
                                   alert("Datos guardados correctamente");
                                   $location.path('/claves/' + dataId);
                                   /*$scope.idClave = dataId;
                                   clavesService.getClave($scope.idClave)
                                     .success(function (data) {
                                         $scope.clave = data;
                                     });*/
                               })
                           .error(function (response) {
                               $scope.error = clavesService.obtenerPrimerErrorModelState(response);
                               alert($scope.error);
                           });
               }
           };

           $scope.FechasExpedienteConvertirUTCALocal = function () {
               $scope.clave.FechaOrdenInicioExpediente = $scope.FechaConvertirUTCALocal($scope.clave.FechaOrdenInicioExpediente);
               $scope.clave.FechaAprobacionProyecto = $scope.FechaConvertirUTCALocal($scope.clave.FechaAprobacionProyecto);
               $scope.clave.FechaOrdenDeclaracionUrgencia = $scope.FechaConvertirUTCALocal($scope.clave.FechaOrdenDeclaracionUrgencia);
           }

           $scope.FechaConvertirUTCALocal = function (fecha) {
               if (fecha != null && fecha != "")
                   return new Date(fecha + "Z");

               return undefined;
           }

           $scope.FechasExpedienteAnadir1200 = function () {
               $scope.clave.FechaOrdenInicioExpediente = $scope.FechaAnadir1200($scope.clave.FechaOrdenInicioExpediente);
               $scope.clave.FechaAprobacionProyecto = $scope.FechaAnadir1200($scope.clave.FechaAprobacionProyecto);
               $scope.clave.FechaOrdenDeclaracionUrgencia = $scope.FechaAnadir1200($scope.clave.FechaOrdenDeclaracionUrgencia);
           }

           $scope.FechaAnadir1200 = function (fecha) {
               if (fecha != null && fecha != "")
                   return new Date(fecha.setHours(12, 0));

               return undefined;
           }

       }]);